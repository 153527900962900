/* .icon{
    margin:auto !important;
}
.popup{
    font-family: Calibri,Roboto,"Helvetica Neue",Arial,sans-serif;
    display: block !important;
}
.title{
    text-align: center !important;
    font-size: 14.5px;
    font-weight: 600;
    line-height: normal;
    color:#000;
}
.container{
     width: 100% !important;
    background: radial-gradient(black, transparent) !important;
}

.htmlContainer{ 
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.5 !important;
    text-align: center !important;
} */
.actions{
    justify-content: center !important;
}
.actions button{
    font-family: Calibri,Roboto,"Helvetica Neue",Arial,sans-serif;
    font-size: 1rem !important;
    line-height: 1.5 !important;
}

.deleteConfirmButton:focus{
    box-shadow:0 0 0 3px rgba(254, 86, 86,50%) !important;
}
 body {
  margin: 0;
  /* zoom: 80%; */
  font-family:'Segoe UI';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
}
*{
  box-sizing: border-box;
}
#root{
  height:100vh;
  /* height:100vh; */
}

.BaseTable{
  font-size: 14.5px !important;
}


.swal2-container{
  z-index: 10600 !important;
}
.swal2-icon{
  transform: scale(0.5) !important;
  margin-top: 0px !important;
}
.swal2-title{
  text-align: center !important;
  font-family: Calibri,Roboto,"Helvetica Neue",Arial,sans-serif !important; 
  margin: 0.5em 1em !important;
  padding: 0 !important;
  font-size: 1em !important;
  font-weight: 600 !important;
  line-height: normal !important;
  color:#000 !important;
}
.swal2-html-container{
  margin-top:0px !important;
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  text-align: center !important;
}
.swal2-popup{
    font-family: Calibri,Roboto,"Helvetica Neue",Arial,sans-serif !important;
    grid-column: 1/4!important;
    grid-row: 1/4!important;
    width: auto !important;
    display: block !important;
    max-width: 500px !important;
    min-width:min(100%,400px) !important;
}